import React from 'react';
import './App.css';
import $, { nodeName, trim } from 'jquery';
import { initializeApp } from "firebase/app";

import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  collectionGroup,
  addDoc,
  getDocs,
  query,
  orderBy,
  limit,
  onSnapshot,
  setDoc,
  where,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getPerformance } from 'firebase/performance';
import { isEmpty } from '@firebase/util';
import reactDom from 'react-dom';

var app = null;
var db = null;

class Synthese extends React.Component {
  constructor(props){
    super(props);
   
    app = this.props.firebase;
    db = getFirestore(app);

    this.state = {
      nbr_msg: 0,
      nbr_msg_love: 0,
      userH: [],
      userF: [],
      userB: [],
      msgH: 0,
      msgF: 0,
      msgB: 0,
      user_msgs: []
    }
  }

  clearState(){
    this.setState({
      nbr_msg: 0,
      nbr_msg_love: 0,
      userH: [],
      userF: [],
      userB: [],
      msgH: 0,
      msgF: 0,
      msgB: 0,
      user_msgs: []
    });
  }

// Loads chat messages history and listens for upcoming ones.
addMessagesListener() { 
  try{
    let q = query(collection(db, 'messages'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
          if(change.type == 'added'){
            this.loadMessagesInfos();
          }
        }
      )
    });
  } catch(e){
  }
}


  /**
   * Is element exist on array
   * @param {*} data 
   * @param {*} key 
   * @returns 
   */
   keyExists(data, key1, key2) {
    return data.some(function(el) {
      return (
              (el.avtIn.pseudo === key1 && el.avtOut.pseudo === key2) 
                  || (el.avtIn.pseudo === key2 && el.avtOut.pseudo === key1)
            );
    }); 
  }

  keyMsgExists(data, key) {
    return data.some(function(el) {
      return (el === key)
    }); 
  }

async loadMessagesInfos(){
  let pseudosH = [];
  let pseudosF = [];
  let pseudosB = [];
  let usersAvatars = [];

    let q = query(collection(db, "utilisateurs"));
    let querySnapshot = await getDocs(q);
    //
    querySnapshot.forEach((doc) => {
      if(doc.data().civilite == "un homme"){
        pseudosH.push(doc.data().pseudo);
      } else if(doc.data().civilite == "une femme"){
        pseudosF.push(doc.data().pseudo);
      } else{
        pseudosB.push(doc.data().pseudo);
      }
      usersAvatars[doc.data().pseudo] = doc.data();
    });

    q = query(collection(db, "messages"), orderBy('date_creation', 'desc'));
    querySnapshot = await getDocs(q);
    
    let maxMChatHistory = 5; let idxHistory = 0;
    let nbr_msg = 0;  let nbr_msg_love = 0;
    let nbr_msg_h = []; let nbr_msg_f = []; let nbr_msg_b = [];
    let msgsAll = [];

    // Loop
    querySnapshot.forEach((doc) => {

          // Last messages ---------------------------------------
          if(idxHistory < maxMChatHistory){
            if(doc.data().pseudo_src && doc.data().pseudo_src != ''){
              if(!this.keyExists(msgsAll, doc.data().pseudo_src, doc.data().pseudo)){
                msgsAll.push({avtIn: usersAvatars[doc.data().pseudo_src], avtOut: usersAvatars[doc.data().pseudo]});
                idxHistory++;
              }
            } else{
              if(!this.keyExists(msgsAll, doc.data().pseudo_dest, doc.data().pseudo)){
                msgsAll.push({avtIn: usersAvatars[doc.data().pseudo_dest], avtOut: usersAvatars[doc.data().pseudo]});
                idxHistory++;
              }
            }
          }

          if(doc.data().pseudo_src && doc.data().pseudo_src != ''){
            // All message
            nbr_msg++;
            //
            if(doc.data().message == "OK_HEART"){
              nbr_msg_love++;
            }
            let pseudo = doc.data().pseudo_src;
        
            if(pseudosH.includes(pseudo)){
              let objIndex = nbr_msg_h.findIndex((obj => obj.psd == pseudo)); 
              if(objIndex >= 0){
                nbr_msg_h[objIndex].nbr = nbr_msg_h[objIndex].nbr+1;
              } else{
                nbr_msg_h.push({psd:pseudo, nbr:1});
              }
              
            } else if(pseudosF.includes(pseudo)){
              let objIndex = nbr_msg_f.findIndex((obj => obj.psd == pseudo));
              if(objIndex >= 0){
                nbr_msg_f[objIndex].nbr = nbr_msg_f[objIndex].nbr+1;
              } else{
                nbr_msg_f.push({psd:pseudo, nbr:1});
              }

            } else if(pseudosB.includes(pseudo)){
              let objIndex = nbr_msg_b.findIndex((obj => obj.psd == pseudo));
              if(objIndex >= 0){
                nbr_msg_b[objIndex].nbr = nbr_msg_b[objIndex].nbr+1;
              } else{
                nbr_msg_b.push({psd:pseudo, nbr:1});
              }
            }
          }
    });

    // Max msg by user
    let maxH = 0; let maxF = 0; let maxB = 0;
    let pseudoH = []; let pseudoF = []; let pseudoB = [];
    
    nbr_msg_h.forEach((user) =>{
        if(user.nbr >= maxH){
          pseudoH = usersAvatars[user.psd];
          maxH = user.nbr;
        }
    });
    nbr_msg_f.forEach((user) =>{
      if(user.nbr >= maxF){
        pseudoF = usersAvatars[user.psd];
        maxF = user.nbr;
      }
    });
    nbr_msg_b.forEach((user) =>{
      if(user.nbr >= maxB){
        pseudoB = usersAvatars[user.psd];
        maxB = user.nbr;
      }
    });

    this.setState({user_msgs: msgsAll});
    this.setState({nbr_msg: nbr_msg});

    this.setState({userH: pseudoH});
    this.setState({userF: pseudoF});
    this.setState({userB: pseudoB});

    this.setState({msgH: maxH});
    this.setState({msgF: maxF});
    this.setState({msgB: maxB});

    this.setState({nbr_msg_love: nbr_msg_love});
  }

  componentDidMount(){
    $("#syn_row_1, #syn_row_2").css("margin-top", ($(window).height()-580)/3);
    $(".container").css("text-align", "center");
    $("body").css("text-align", "center");
    //
    setTimeout(() => {
      $("#row_synth").css("margin-top", (($(window).height()-600)/2)+"px");  
    }, 100);
    
    //
    this.loadMessagesInfos();
    this.addMessagesListener();
  }

  render(){
    return (
    <div className="container">
        <div className="row" id="row_synth">
            <div style={{width:'47%',marginRight:'6%'}}>
                <div className="col-12 box-synth" style={{height:'50px'}}>
                  <span style={{fontSize: '40px',color:'#FD88A5',fontWeight: 'bold', lineHeight: '30px', transform: 'scale(1.5, 1.1)'}}>ça discute en live</span>
                </div>
                <div className="col-12 box-synth" style={{height:'510px',marginTop: '20px'}}>
                    <table style={{width: '100%'}}>
                    {this.state.user_msgs.map((user) => 
                        <tr>
                          <td style={{textAlign: 'left'}}>    
                              <img src={user.avtIn.avatar} alt={user.avtIn.pseudo} className={'img-avatar-'+user.avtIn.civilite.split(' ')[1]} style={{width: '97px',borderRadius: '60px'}} />
                          </td>
                          <td style={{textAlign: 'center'}}>
                              <img src="resources/img/synth/chatting.gif" style={{width:'80px'}} />
                              <img src="resources/img/synth/chatting.gif" style={{width:'80px',transform: 'scaleX(-1)',}} />
                           </td>
                           <td style={{textAlign: 'right'}}>
                              <img src={user.avtOut.avatar} alt={user.avtOut.pseudo} className={'img-avatar-'+user.avtOut.civilite.split(' ')[1]} style={{width: '97px',borderRadius: '60px'}} />
                           </td>   
                        </tr>
                    )}
                    </table> 
                </div>
            </div>
            <div style={{width:'47%'}}>
                <div className="row">
                    <div className="box-synth" style={{width:'47%',marginRight:'6%',height:'280px'}}>
                        <img src="resources/img/synth/love_2.gif" style={{height: '180px',paddingTop: '15px'}}/>
                        <div style={{marginTop: '17px'}}>
                            <h1 className="box-sytnth-title" style={{width: '50%',color:'#FF4C48',float: 'left',textAlign: 'right'}}>{this.state.nbr_msg_love }</h1>
                            <h1 className="box-sytnth-title2" style={{width: '10%',color:'#FF4C48',float: 'left',paddingTop: '29px',textAlign: 'left'}}>loves échangés</h1>
                        </div>
                    </div>
                    <div className="box-synth" style={{width:'47%',height:'280px'}}>
                        <img src="resources/img/synth/icon_msg.gif" style={{height: '180px',paddingTop: '25px'}} />
                        <div style={{marginTop: '17px'}}>
                            <h1 className="box-sytnth-title" style={{width: '50%',color:'#BC0941',float: 'left',textAlign: 'right'}}>{this.state.nbr_msg }</h1>
                            <h1 className="box-sytnth-title2" style={{width: '10%',color:'#BC0941',float: 'left',paddingTop: '29px',textAlign: 'left'}}>messages échangés</h1>
                        </div>    
                    </div>
                </div>

                <div className="row">
                    <div style={{height:'280px',marginTop: '20px', width:'32%',marginRight:'2%'}}  className={'box-synth img-synth-'+(this.state.userF.civilite?this.state.userF.civilite.split(' ')[1]:'')}>
                        <img src={this.state.userF.avatar} style={{width: '120px',height:'120px',borderRadius: '60px',marginTop:'30px'}} className={'img-avatar-'+(this.state.userF.civilite?this.state.userF.civilite.split(' ')[1]:'')}/>
                        <h1 style={{fontSize: '13px',color: 'gray'}}>{this.state.userF.pseudo}&nbsp;</h1>
                        <h1 style={{fontSize: '13px',color: 'gray'}}>{this.state.userF.numero}&nbsp;</h1>
                        <h1 className="box-sytnth-title" style={{marginTop: '-10px'}}>{this.state.msgF>0?this.state.msgF:'-'}</h1>                        
                        <h1 className="box-sytnth-title2">messages échangés</h1>
                    </div>
                    <div style={{height:'280px',marginTop: '20px', width:'32%',marginRight:'2%'}} className={'box-synth img-synth-'+(this.state.userH.civilite?this.state.userH.civilite.split(' ')[1]:'')}>
                        <img src={this.state.userH.avatar} style={{width: '120px',height:'120px',borderRadius: '60px',marginTop:'30px'}} className={'img-avatar-'+(this.state.userH.civilite?this.state.userH.civilite.split(' ')[1]:'')}/>
                        <h1 style={{fontSize: '13px',color: 'gray'}}>{this.state.userH.pseudo}&nbsp;</h1>
                        <h1 style={{fontSize: '13px',color: 'gray'}}>{this.state.userH.numero}&nbsp;</h1>
                        <h1 className="box-sytnth-title" style={{marginTop: '-10px'}}>{this.state.msgH>0?this.state.msgH:'-'}</h1>
                        <h1 className="box-sytnth-title2">messages échangés</h1>
                    </div>
                    <div style={{height:'280px',marginTop: '20px', width:'32%'}}  className={'box-synth img-synth-'+(this.state.userB.civilite?this.state.userB.civilite.split(' ')[1]:'')}>
                    <img src="resources/img/coq_rouge.gif" style={{width:'90%',marginTop:'30px'}} />
                    </div>
                </div>
            </div>

         </div>  
      </div>
     )}
}

export default Synthese;
