import React from 'react';
import './App.css';
import $, { nodeName, trim } from 'jquery';
import { initializeApp } from "firebase/app";
import { animateScroll } from "react-scroll";


import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  collectionGroup,
  addDoc,
  getDocs,
  query,
  orderBy,
  limit,
  onSnapshot,
  setDoc,
  where,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { getPerformance } from 'firebase/performance';
import Compress from 'compress.js';
import reactDom from 'react-dom';
import { getAllByPlaceholderText } from '@testing-library/react';

var app = null;
var db = null;
var storage = null;
var is_reconnect = false;
var is_live_event = false;
var interval = null;
let unsubscribe = null;

let msgsAll = [];
let userAll = [];
let msgsAllState = [];

let lastDtSynchro = new Date(new Date().setHours(new Date().getHours() - 10)).getTime();
let _BAD_WORDS = [
  "putain", "emmerde", "abruti", "debile", "chier", "salope", "salaud", "ta gueule",
  "cretin", "imbecile", "con", "connard", "connasse", "tarlouze"
];

class Chat extends React.Component {
  constructor(props){
    super(props);
   
    // Init firebase
    app = this.props.firebase;
    db = getFirestore(app);
    storage = getStorage(app);

    // Init state
    this.state = {
      avatar:'', avata_tp:'', objectif:'', age:'', civilite:'', pseudo:'', numero:'',
      clients:[], 
      client_dest:[],
      messages:[],
      historiques:[],
      is_new_msg: false,
      is_change_avatar: false
    }
  }

  // Clear all state vars
  clearState(){
    this.setState({
      avatar:'', avata_tp:'', objectif:'', age:'', civilite:'', pseudo:'', numero:'',
      clients:[], 
      client_dest:[],
      messages:[],
      historiques:[],
      is_new_msg: false,
      is_change_avatar: false
    });
  }

  // Generate uniq ID
  generateRandom(){
      const min = 1;
      const max = 100;
      const rand = min + Math.random() * (max - min);
      
      return parseInt(rand);
  }

  /**
   * 
   * @returns Create new user
   */
  async manageUser() {
    if(is_reconnect){
      this.handelStep('recherche');
      return;  
    }
    
    let currState = this.state;
    let dtCreate = new Date().getTime();
    let userId = 'USR_'+dtCreate+"_"+this.generateRandom();
    try {
      await setDoc(doc(db, 'utilisateurs', userId), {
        pseudo: currState.pseudo.toUpperCase(),
        numero: currState.numero,
        objectif: currState.objectif,
        civilite: currState.civilite,
        age: currState.age,
        //avatar_tp: currState.avatar_tp,
        avatar: currState.avatar,
        date_creation: dtCreate,
        date_connexion: '',
        id: userId
      });
    }
    catch(error) {
      console.error('Error writing new message to Firebase Database', error);
    }

    //this.handelStep('historique');
    this.loadClients(true);
  }

  /**
   * Uodate last connection user date
   * @param {*} userId 
   */
  async updateUserConnection(userId) {
    let dtCreate = new Date().getTime();
    const userRef = doc(db, "utilisateurs", userId);
    await updateDoc(userRef, {
      date_connexion: dtCreate
    });
  }

  /**
   * Update user avatar icon
   */
    async updateUserAvatar(avatar) {
      let q = query(collection(db, "utilisateurs"), where("pseudo", "==", this.state.pseudo));
      let querySnapshot = await getDocs(q);
      
      let user = null;
      querySnapshot.forEach((doc) => {
        user = doc.data();
      });
      const userRef = doc(db, "utilisateurs", user.id);
      await updateDoc(userRef, {
        avatar: avatar
      });
    }

  /**
   * Update if message readed
   * @param {*} msgId 
   */
  async updateMsgData(msgId) {
    const msgRef = doc(db, "messages", msgId);
    await updateDoc(msgRef, {
      is_lu: true
    });
  }

  /**
   * Ipload file to fire storage
   * @param {*} e 
   */
  async uploadFile(e){
    e.preventDefault();
    e.stopPropagation();

    let dtCreate = new Date().getTime();
    let randId = dtCreate+"_"+this.generateRandom();

    const storage = getStorage();
    var file = e.target.files[0];
    const imageRef = ref(storage, 'images/' + randId+"_"+file.name);

    const metadata = {
      contentType: 'image/jpeg',
    };
    
    window.showLoading();

    uploadBytesResumable(imageRef, file, metadata)
      .then((snapshot) => {
        //console.log('Uploaded', snapshot.totalBytes, 'bytes.');
        //console.log('File metadata:', snapshot.metadata);
        // Let's get a download URL for the file.
        getDownloadURL(snapshot.ref).then((url) => {
          
          this.sendMessage("UPFILE", url);
          window.hideLoading();
          //console.log('File available at', url);
          // ...
        });
      }).catch((error) => {
        console.error('Upload failed', error);
        // ...
      });

      //setLoading(false);
      document.getElementById("cam_input").value = null
  }

  /**
     * Ipload file to fire storage
     * @param {*} e 
     */
  async uploadAvatar(e){
    e.preventDefault();
    e.stopPropagation();
    const compress = new Compress()
    let isUpd = false;
    let dtCreate = new Date().getTime();
    let randId = dtCreate+"_"+this.generateRandom();

    const storage = getStorage();
    var file = e.target.files[0];
    


    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: 500, // the max width of the output image, defaults to 1920px
      maxHeight: 500, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })

    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    console.log('plop');
    const imageCompress = Compress.convertBase64ToFile(base64str, imgExt)
    console.log(imageCompress);
    //return imageCompress;
    const imageRef = ref(storage, 'images/' + randId+"_"+imageCompress.name);



    const metadata = {
      contentType: 'image/jpeg',
    };
    let that = this;
    window.showLoading();

    uploadBytesResumable(imageRef, imageCompress, metadata)
      .then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
              //Aperçu
              $("#upload_lnk").css('background-image', 'url('+url+')')
                  .css('background-size', 'contain')
                  .css('background-repeat', 'no-repeat');
              $("#upload_lnk").find("i").hide();
              $("#upload_lnk").attr("isUpd", "1");

              if(isUpd){// Si maj avatar
                that.setState({avatar: url});
                that.updateUserAvatar(url);
              } else{
                that.setState({avatar: url});
              }
              window.hideLoading();
          });
      }).catch((error) => {
        console.error('Upload failed', error);
      });
      document.getElementById("upload_input").value = null
  }

  /**
   * Check if message exist on temporary array
   * @param {*} msgId 
   * @returns 
   */
  msgExists(msgId) {
    return this.state.messages.some(function(el) {
      return el.id === msgId;
    }); 
  }

  /**
   * Check if user exist on temporary array
   * @param {*} msgId 
   * @returns 
   */
     userExists(userId) {
      return this.state.clients.some(function(el) {
        return el.id === userId;
      }); 
    }

  /**
   * Loads users and listens for upcoming ones.
   * @returns 
   */
  addUsersListener() { 
    try{
      let q = query(collection(db, "utilisateurs"), orderBy("pseudo", "asc"));
      
      unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
          if(change.type == 'added'){
            if(!this.userExists(change.doc.data().id)){
                this.setState({clients: [...this.state.clients, change.doc.data()] });
            }
          };
        });
      });
    } catch(e){
      alert("err===>"+e.message);
    }
  }

  /**
   * Loads chat messages history and listens for upcoming ones.
   * */
  addMessagesListener() { 
    if(this.state.pseudo == '' || is_live_event){
      return;
    }
    
    try{
      
      let q = query(collection(db, "messages"), 
            where("pseudo", "==", this.state.pseudo), 
            orderBy("date_creation", "asc"));
      

/*
let dtRef = lastDtSynchro = (new Date(new Date().setHours(new Date().getMinutes() - 20)).getTime();
let q = query(collection(db, "messages"), 
            where("pseudo", "==", this.state.pseudo), 
      where("date_creation", ">", dtRef),     
      orderBy("date_creation", "asc"));
*/


      unsubscribe = onSnapshot(q, (snapshot) => {
        var isPassed = false;
      snapshot.docChanges().forEach((change) => {
          if(change.type == 'added'){
            if(!this.msgExists(change.doc.data().id)){
              let cleintId = this.state.client_dest.pseudo;

              if(document.readyState === "complete" && change.doc.data().pseudo_src &&
                  (!$('#step-chat').is(':visible') || this.state.client_dest.pseudo!=change.doc.data().pseudo)){
                    // Le client encous de chat n'est pas le même que le client qui a envoyé
                    
                    let isNotCurrPsdChat = (!$('#step-chat').is(':visible') 
                                || this.state.client_dest.pseudo != change.doc.data().pseudo_src);

                    if(isNotCurrPsdChat){
                      this.setState({is_new_msg: true});
                    }
                    
                    // Ajouter les messages non lus
                    if(isNotCurrPsdChat 
                          && change.doc.data().is_lu !== true 
                          && !this.keyExists(msgsAllState, change.doc.data().pseudo_src)){
                      msgsAllState.push({psd: change.doc.data().pseudo_src, is_lu:false});
                    }
                    // Supprimer des goupes de l'historique
                    let histoTemp = this.state.historiques;
                    histoTemp = histoTemp.filter(item => item.psd !== change.doc.data().pseudo_src);
                    // Ajouter dans l'historique index 0
                    let elmnt = {psd: change.doc.data().pseudo_src, msg: change.doc.data(), cli: this.getClientByPseudo(this.state.clients, change.doc.data().pseudo_src)};
                    Array.prototype.splice.apply(histoTemp, [0, 0].concat(elmnt));
                    this.setState({historiques: histoTemp });
              }

              // Marquer comme lu
              if($('#step-chat').is(':visible')){
                  if(change.doc.data().pseudo_src==cleintId){
                    this.updateMsgData(change.doc.data().id);
                  }
                  //
                  if(change.doc.data().pseudo_src==cleintId || change.doc.data().pseudo_dest==cleintId){
                    this.setState({messages: [...this.state.messages, change.doc.data()] }, () => {
                      document.getElementById("msgs_div").scrollTop = document.getElementById("msgs_div").scrollHeight;
                    });
                  }
                  if(change.doc.data().pseudo != this.state.pseudo && !isPassed){
                     //this.loadHistoriqueMessage();
                     isPassed = true;
                  }
              } else if(change.doc.data().pseudo_src && change.doc.data().pseudo_src != ''){
                //if($('#step-historique').is(':visible')){
                  if(!isPassed){
                    //this.loadHistoriqueMessage();
                    isPassed = true;
                  }
                //}
                if(document.visibilityState == 'hidden'){
                  window.displayNotification(change.doc.data().pseudo_src+":"+change.doc.data().message);
                }
              }
            }
          };
        });
        isPassed = false;
        is_live_event = true;
      });
    } catch(e){
      is_live_event = false;
      alert("err===>"+e.message);
    }
  }

/**
 * Save new messages to Cloud Firestore.
 * Evry message is saved for both users IN/OUT
 */
async sendMessage(val, filePath) {
  $("#match-close").trigger("click");
  let msg = (val == null ? $("#message").val() : val);
  if(trim(msg) == ''){
    return null;
  }
  
   if(val != 'KO_HEART' && val != 'OK_HEART' && val != 'UPFILE'){
      if(this.containsBadWords(msg)){
        window.alertify.error("<h3>Votre message a été bloqué.<br>Merci d'utiliser d'autres mots.</h3>");
        return;
      }
  }

  let dtCreate = new Date().getTime();
  let currState = this.state;

  let id = dtCreate+"_"+this.generateRandom();
  let inId = 'MSGO_'+id;
  let outId = 'MSGI_'+id;
  // Add a new message entry to the Firebase database.
  try {
    await setDoc(doc(db, 'messages', outId), {
      pseudo_full: currState.pseudo+"|"+currState.client_dest.pseudo,
      pseudo: currState.pseudo,
      avatar: currState.avatar,
      numero: currState.numero,
      message: msg,
      pseudo_dest: currState.client_dest.pseudo,
      numero_dest: currState.client_dest.numero,
      date_creation: dtCreate,
      is_lu: true,
      id: outId,
      file_path: filePath ? filePath : ''
    });
    await setDoc(doc(db, 'messages', inId), {
      pseudo_full: currState.client_dest.pseudo+"|"+currState.pseudo,
      pseudo: currState.client_dest.pseudo,
      avatar: currState.client_dest.avatar,
      numero: currState.client_dest.numero,
      message: msg,
      pseudo_src: currState.pseudo,
      numero_src: currState.numero,
      date_creation: dtCreate,
      is_lu: false,
      id: inId,
      file_path: filePath ? filePath : ''
    });
    document.getElementById('message').value = '';

    return id;
  }
  catch(error) {
    console.error('Error writing new message to Firebase Database', error);
  }

  return null;
}

//--------------------------------------------------------------

  //** Mots clés sans accents en miniscule */
  containsBadWords(msg){
    if($.trim(msg) == '') {
        return false;
    }
    // Casse
    msg = this.getNormalizeWord(msg);

    var error = 0;
    if (msg.split(' ').some(part => _BAD_WORDS.includes(part))) {
        error = error + 1;
    }
    return (error > 0);
  }

  getNormalizeWord(txt){
    // Casse
    txt = txt.toLowerCase();
    // Supprimer les accents
    txt = txt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
    return txt;
  }
//----------------------------------------------------------------

/**
 * Load all clients and filter by form sublitted
 */
async loadClients(isAll){
      let pseudo = $.trim($("#search_pseudo").val()).toLowerCase();
      let numero = $.trim($("#search_numero").val()).toLowerCase();
      let civilite = $.trim($("#search_civilite").val());
      let objectif = $.trim($("#search_objectif").val());
      let clients = [];  
      let q = query(collection(db, 'utilisateurs'), orderBy('pseudo', 'asc'));
      const querySnapshot = await getDocs(q);
      let isSrch = (pseudo!='' || numero!='' || civilite!='' || objectif!='');

      if(isSrch && !isAll){
        querySnapshot.forEach((doc) => {
          if(doc.data().pseudo != this.state.pseudo){
              if(pseudo != ''){
                if(doc.data().pseudo.toLowerCase().indexOf(pseudo) != -1){
                  clients.push(doc.data());
                } 
              } else if(numero != ''){
                if(doc.data().numero.toLowerCase().indexOf(numero) != -1){
                  clients.push(doc.data());
                } 
              } else if(civilite != '' && objectif != ''){
                if(civilite == doc.data().civilite && objectif == doc.data().objectif){
                  clients.push(doc.data());
                } 
            } else if(civilite != '' || objectif != ''){
              if(civilite != ''){
                if(civilite == doc.data().civilite){
                  clients.push(doc.data());
                } 
              } else{
                if(objectif == doc.data().objectif){
                  clients.push(doc.data());
                }
              }
            }
        }
      });
    } else{
      querySnapshot.forEach((doc) => {
        if(doc.data().pseudo != this.state.pseudo){
          clients.push(doc.data());
        }
      });
    }
    this.setState({ clients: clients });
    this.handelStep("recherche");
    $("#search_close").trigger("click");
  }

  /**
   * Calculate diff time between to dates
   * @param {*} dt1 
   * @returns 
   */
  diff_date_time(dt1){
    var dt2 = new Date().getTime();
    var diff =(dt2 - dt1) / 1000;
    var diffS = diff;
    var diffM = diff/60; 
    var diffH = diff/60/60;
    
    var txtDiff = "";

    if(diffM < 1){
      txtDiff = Math.abs(Math.round(diffS))+" sec";
    } else if(diffM < 60){
      txtDiff = Math.abs(Math.round(diffM))+" min";
    } else if(diffH < 24){
      var h = Math.abs(Math.round(diffH));
      var restM = Math.abs(Math.round(diffM-(h*60)));
      restM = (''+restM).length==1 ? '0'+restM : restM;

      txtDiff = h+"h"+restM;
    } else{
      var options = { month: 'long', day: 'numeric' };  
      txtDiff = new Date(dt1).toLocaleDateString('fr-FR', options);
    }

    return txtDiff;  
  }

  /**
   * Diff dates
   * @param {*} dt2 
   * @param {*} dt1 
   * @param {*} tp 
   * @returns 
   */
  diff_date(dt2, dt1, tp){
    var diff =(dt2 - dt1) / 1000;
    diff = (tp == 'M') ? diff/60 : diff/60/60;

    return Math.abs(Math.round(diff));  
 }

 /**
  * Check user by num and manage forwardin to sceen
  */
  async connectByNumero(){
    const q = query(collection(db, "utilisateurs"), where("numero", "==", $("#token").val()));
    const querySnapshot = await getDocs(q);
    let client = null;
    querySnapshot.forEach((doc) => {
      client = doc.data();
    });

    let serverDate = new Date().getTime();
    let dtConn = null;
    if(client != null){
      dtConn = client.date_connexion==='' ? client.date_creation : client.date_connexion;
    } 

    //let isMore12h = (this.diff_date(serverDate, dtConn, 'H') >= 12);
    if(client == null/* || isMore12h*/){
      this.handelStep('identite');
    } else{
      is_reconnect = true;
      this.setConnectedUserInfos(client);
      this.updateUserConnection(client.id);

      if(this.diff_date(serverDate, dtConn, 'M') >= 30){
          this.handelStep('pseudo');
      } else{
          //this.handelStep('historique');
          //this.loadClients(true);
          this.handelStep('pseudo');
      }
    }
  }

  /**
   * Is element exist on array
   * @param {*} data 
   * @param {*} key 
   * @returns 
   */
  keyExists(data, key) {
    return data.some(function(el) {
      return el.psd === key;
    }); 
  }

  /**
   * Get user by login 
   * @param {*} clients 
   * @param {*} pseudo 
   * @returns 
   */
  getClientByPseudo(clients, pseudo){
    let curr = null;
    clients.map((data) => {
        if(data.pseudo === pseudo){
          curr = data;
        }
    });
    return curr;
  }

/**
     * Load messages order by last message
     */
  async loadHistoriqueMessage() {
    let isNotLuExist = false;
    let msgsAllData = [];
    /*const q = query(collection(db, "utilisateurs"));
    const querySnapshot = await getDocs(q);
    let clients = [];
    querySnapshot.forEach((doc) => {
      clients.push(doc.data());
    });*/

    let clients = this.state.clients;
    
    // Reçus
    let qIn = query(collection(db, "messages"), 
          where("pseudo", "==", this.state.pseudo), 
          where("date_creation", ">", lastDtSynchro),
          orderBy('date_creation', 'desc'));
          
    let queryIn = await getDocs(qIn);
    var lastDate = null;
    queryIn.forEach((doc) => {
        if(lastDate == null){ 
          lastDate = doc.data().date_creation;
        }
        if(doc.data().pseudo_src && doc.data().pseudo_src != ''){
          if(!doc.data().is_lu){
            isNotLuExist = true;
            if(!this.keyExists(msgsAllState, doc.data().pseudo_src) 
                && doc.data().is_lu !== true){
              msgsAllState.push({psd: doc.data().pseudo_src, is_lu:false});
            }
          }
          if(!this.keyExists(msgsAllData, doc.data().pseudo_src)){
            msgsAllData.push({psd: doc.data().pseudo_src, msg: doc.data(), cli: this.getClientByPseudo(clients, doc.data().pseudo_src)});
          }
        } else{
          if(!this.keyExists(msgsAllData, doc.data().pseudo_dest)){
            msgsAllData.push({psd: doc.data().pseudo_dest, msg: doc.data(), cli: this.getClientByPseudo(clients, doc.data().pseudo_dest)});
          }
        }
    });
    //Ajouter nouveaux messages
    msgsAllData.forEach((doc) => {
      if(!this.keyExists(msgsAll, doc.psd)){
        msgsAll.push(doc);
      }
    });
    // Ajouter les anciens
    msgsAll.forEach((doc) => {
      if(!this.keyExists(msgsAllData, doc.psd)){
        msgsAllData.push(doc);
      }
    });
    this.setState({historiques: msgsAllData});
    this.setState({is_new_msg: isNotLuExist});
    lastDtSynchro = (lastDate == null ? new Date(new Date().setHours(new Date().getMinutes() - 120)).getTime() : lastDate);
  }
  /**
   * Load messages for one user
   */
  async loadClientMessages() {
    let pseudoFullIn = this.state.pseudo+"|"+this.state.client_dest.pseudo;

    const q = query(collection(db, "messages"), where("pseudo_full", "==", pseudoFullIn), orderBy('date_creation', 'asc'));
    const querySnapshot = await getDocs(q);

    var msgs = [];
    querySnapshot.forEach((doc) => {
      msgs.push(doc.data());

      // Marquer comme lu
      this.updateMsgData(doc.data().id);
    });

    this.setState({messages: msgs}, () => {
        document.getElementById("msgs_div").scrollTop = document.getElementById("msgs_div").scrollHeight;
    });
    this.setState({is_new_msg: false});
    // Recharger pour gérer la notification global des nouveaux messages
    //this.loadHistoriqueMessage();
  }

  /**
   * Load user by login and forward
   * @param {*} pseudo 
   * @param {*} isDirect 
   * @returns 
   */
  async loadClient(pseudo, isDirect) {
    const q = query(collection(db, "utilisateurs"), where("pseudo", "==", pseudo));
    const querySnapshot = await getDocs(q);
    let client = null;
    querySnapshot.forEach((doc) => {
      client = doc.data();
    });

    this.setState({client_dest: (client==null?[]:client)});
    if(isDirect){
      this.handelStep('chat');
      // Egalement dans le tableau global des messages
      msgsAllState = msgsAllState.filter(item => item.psd !== pseudo);
    } else{
      this.handelStep('recap');
    }

    return client;
  }

  /**
   * Check user login
   * @returns 
   */
  async checkUserPseudo() {
    /* toUpperCase */
    let userPseudo = $("#pseudo").val().toUpperCase();
    let userNumero = $("#token").val();
    
    let userByNumero = null;
    let q = query(collection(db, "utilisateurs"), where("numero", "==", userNumero));
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        userByNumero = doc.data();
    });

    if(userByNumero != null && userByNumero.pseudo == userPseudo){
      this.setConnectedUserInfos(userByNumero);
      return true;
    }

    return false;
  }

  /**
   * Get user by login
   * @param {*} psd 
   * @returns 
   */
  async getUserByPseudo(psd) {
    let userPseudo = (psd ? psd : $("#pseudo").val());
    let q = query(collection(db, "utilisateurs"), where("pseudo", "==", userPseudo));
    let querySnapshot = await getDocs(q);
    
    let user = null;
    querySnapshot.forEach((doc) => {
      user = doc.data();
    });
    return user;
  }

  /**
   * Put user target infos on state
   * @param {*} user 
   */
  setConnectedUserInfos(user){
    this.setState({pseudo: user.pseudo, numero: user.numero, 
      avatar:user.avatar, avata_tp:user.avata_tp, objectif:user.objectif, 
      age:user.age,
      civilite:user.civilite}, ()=>{
        this.addMessagesListener();
      });
  }

  // -----------------------------------On ready--------------------------------------------------
  componentWillUnmount(){
    if(interval != null){
      clearInterval(interval);
    }
    if(unsubscribe != null){
      unsubscribe();
    }
  }

  componentDidMount(){
    this.clearState();
    var that = this;

    // Add events liteners
    this.addUsersListener();
    
   // Purger dans n temps 
   interval = setInterval(() => {
      let userPseudo = $("#pseudo").val();

      if($.trim(userPseudo) != ''){
        let serverDate = new Date().getTime();
        let currCli = this.getUserByPseudo();
        /*if((currCli == null || this.diff_date(serverDate, currCli.date_connexion, 'M') >= 120) && !$('#step-chat').is(':visible')){
          this.handelStep('home');
          localStorage.removeItem("javaPsd");
          localStorage.removeItem("javaPsdCli");
          localStorage.removeItem("javaStep");
        }*/
      }
    }, 300000);


    // Restaure session
    if(localStorage.getItem("javaStep") && localStorage.getItem("javaStep") != ''){
      setTimeout(() => {
        $("#pseudo").val(localStorage.getItem("javaPsd"));  
      }, 1000);
      
      this.getUserByPseudo(localStorage.getItem("javaPsd")).then((currCli)=>{
          let serverDate = new Date().getTime();
          if(currCli != null && this.diff_date(serverDate, currCli.date_connexion, 'M') < 120){

            if(localStorage.getItem("javaPsdCli") && localStorage.getItem("javaPsdCli") != null){
              this.getUserByPseudo(localStorage.getItem("javaPsdCli")).then((destCli)=>{
                  this.setState({client_dest: (destCli==null?[]:destCli)});
              });
            }

            this.setState({pseudo: currCli.pseudo, numero: currCli.numero, 
              avatar:currCli.avatar, avata_tp:currCli.avata_tp,age:currCli.age, objectif:currCli.objectif, 
              civilite:currCli.civilite}, () => {
                  if(localStorage.getItem("javaStep") == 'recherche'){
                      this.loadClients();
                  } else{
                    this.handelStep(localStorage.getItem("javaStep"));
                  }
                  // Reload events
                  this.addMessagesListener();

                  if(localStorage.getItem("javaStep") == 'chat'){
                    this.loadHistoriqueMessage();
                  }
            });
        } else{
            $("#pseudo").val('');
            localStorage.removeItem("javaPsd");
            localStorage.removeItem("javaPsdCli");
            localStorage.removeItem("javaStep");
        }
      });
    }
    
    /**-------------------------------START EVENTS LISTENERS-------------------------------- */
    $(document).off('keypress', "#message").on('keypress', "#message", function(e){
      if(e.which == '13') {
        that.sendMessage($(this).val());
      }
    });

    $(document).off('click', "#cam_lnk").on('click', "#cam_lnk", function(){
      $("#cam_input").trigger("click");
    });
    $(document).off('click', "#upload_lnk").on('click', "#upload_lnk", function(){
      $("#upload_input").trigger("click");
    });
    $(document).off('click', "#upload_close").on('click', "#upload_close", function(){
      if($("#upload_lnk").attr("isUpd") != 1){
        window.showGrowlMsg('Veuillez séléctionner une photo.');
        return;
      }

      $("#avatar-btnPop").hide();
      $("#avatar-btnNext").show();

      $("#avatar-close").trigger("click");
    });
    
   /* $(document).off('click', "#avatar-filter a").on('click', "#avatar-filter a", function(){
      $("#avatar-filter a").removeClass("active");
      $(this).addClass("active");

     // $("#avatar-select").find("div[tp]").hide();
     // $("#avatar-select").find("div[tp='"+$.trim($(this).text())+"']").show();
    });*/

  /*  $(document).off('click', "#avatar-select a").on('click', "#avatar-select a", function(){
        that.setState({avatar: $(this).find("img").attr("src")});
        $("#avatar-close").trigger("click");

        if(that.state.is_change_avatar){
          that.updateUserAvatar($(this).find("img").attr("src"));
          window.showGrowlMsg("L'avatar est mis à jour.", "success");
        } else{
          $("#avatar-btnPop").hide();
          $("#avatar-btnNext").show();
        }
    });*/
    $(document).off('click', "#search-div a").on('click', "#search-div a", function(){
      that.loadClient($(this).attr('cl'), false);
    });
    $(document).off('click', "#histo-div a").on('click', "#histo-div a", function(){
      that.loadClient($(this).attr('cl'), true);
    });
    $(document).off('click', "#cat-div .col-3").on('click', "#cat-div .col-3", function(){
        $("#cat-div .col-3").css("background", "");
       
        $(this).css("background","radial-gradient(circle, rgba(231,81,144,1) 14%, rgba(231,81,144,0) 60%)").css("background-size","100% 240%");
          


        $("#cause").val($(this).find(".span-cat-txt-step1").text());
    });

    $(document).off('click', "#search-cat-div .col-3").on('click', "#search-cat-div .col-3", function(){
      
      if($(this).attr("isSel")){
        $(this).css("background", "");
        $("#search_objectif").val('');
      } else{
        $("#search-cat-div .col-3").css("background", "").removeAttr("isSel");
         $(this).css("background", "radial-gradient(circle, rgba(231,81,144,1) 14%, rgba(231,81,144,0) 40%)").css("background-size","100% 240%").attr("isSel", "1");
        

        $("#search_objectif").val($(this).find(".span-cat-txt-step1").text());  
      }      
    });

    $("img").each(function () {
      var $this = $(this);
      this.onerror = function() {
          $this.hide();
      };
    });

    setTimeout(() => {
      $("div[id^='step-']").css("height", ($(window).height())+"px").css("overflow-y", "none");
      $("#msgs_div").css("height", ($(window).height())+"px !important ").css("overflow-y", "none");
      $("#chat-div").css("height", ($(window).height()-120)+"px");
      $("#search-div,#histo-div").css("height", ($(window).height())+"px").css("overflow-y", "auto");
      $("#step-home").css("height", ($(window).height())+"px").css("overflow-y", "auto");    


      $(document).off('change', "#token, #pseudo").on('change', "#token, #pseudo", function(){
          $(this).val($(this).val().toUpperCase());
      });    
    }, 500);
  }

  /**
   * Manage navigation screens
   * @param {*} num 
   * @returns 
   */
  handelStep(num){
    var that = this;
    if(num == 'identite'){
      if($.trim($("#token").val()) == ''){
          window.showGrowlMsg('Veuillez saisir vote numéro de badge.');
          return;
      } else if(!$("#token").val().match(/^[0-9a-zA-Z]+$/)){
        window.showGrowlMsg('Veuillez saisir uniquement des chiffes et des caractères alphabétiques.');
        return;
      }
      that.setState({numero: $("#token").val()});

     // $("#avatar-img").html('');
     // $("#avatar-btnPop").show();
     // $("#avatar-btnNext").hide();
    } else if(num == 'recap-cpt'){
        if($.trim($("#pseudo").val()) == ''){
          window.showGrowlMsg('Veuillez saisir vote pseudo pour la soirée.');
          return;
        } else if(!$("#pseudo").val().match(/^[0-9a-zA-Z]+$/)){
          window.showGrowlMsg('Veuillez saisir uniquement des chiffes et des caractères alphabétiques.');
          return;
        }
       // 
       if(is_reconnect){
          // Cas reconnextion avec numéro et pseudo différent
          that.checkUserPseudo().then((isPseudoOk)=>{
              if(!isPseudoOk){
                window.showGrowlMsg('Ce pseudo ne correspond pas au badge, veuillez recommencer.'); 
                window.clearForm();
                this.handelStep('home'); 
                return;
              } else{
                $("div[id^='step']").hide();
                $("#step-"+num).show();
                // Save state
                this.saveChatState(num);
              }
            });
            return;
        } else{
          that.getUserByPseudo().then((user)=>{
              if(user != null){
                window.showGrowlMsg('Ce pseudo est déjà utilisé.');
                this.handelStep('pseudo'); 
                return;
              } else{
                that.setState({pseudo: $("#pseudo").val()},()=>{
                  this.addMessagesListener();
                });

                $("div[id^='step']").hide();
                $("#step-"+num).show();

                // Save state
                this.saveChatState(num);
              }
            });
        }
        
    } else if(num == 'pseudo'){
      if(!is_reconnect){
          if($.trim($("#civilite").val()) == '' || $.trim($("#cause").val()) == ''){
            window.showGrowlMsg('Veuillez remplir tous les champs.');
            return;
          }
          that.setState({civilite: $("#civilite").val()});
          that.setState({objectif: $("#cause").val()});
          that.setState({age: $("#age").val()});
        }
    } else if(num == 'historique'){
        that.loadHistoriqueMessage();
        //that.addMessagesListener();
    } else if(num == 'recherche'){
      
    } else if(num == 'chat'){
      that.loadClientMessages();
      //that.addMessagesListener();
    }

    $("div[id^='step']").hide();
    $("#step-"+num).show();
    // Save state
    this.saveChatState(num);
  }

  /**
   * Save nav state to be restaured if page was reloaded
   * @param {*} num 
   */
  saveChatState(num){
    localStorage.setItem("javaStep", num);
    localStorage.setItem("javaPsd", this.state.pseudo);
    if(this.state.client_dest != null && this.state.client_dest.pseudo){
      localStorage.setItem("javaPsdCli", this.state.client_dest.pseudo);
    }
  }

  /**
   * Show messages on chat screen by type (img, heart, text, ...)
   * @param {*} props 
   * @returns 
   */
  manageMessage(props) {
    let msg = props.msg;
    let currPseudo = props.psd;
    let currPseudoCli = props.cli;
    let isInMsg = (msg.pseudo == currPseudo && msg.pseudo_dest != null);

    let fileSrc = null;
    if(msg.message == 'UPFILE'){
      fileSrc = msg.file_path;
    } else if(msg.message == 'OK_HEART'){
      fileSrc = "resources/img/love_a.gif";
    } else if(msg.message == 'KO_HEART'){
      fileSrc = "resources/img/coeur_brise.gif";
    }
    if(fileSrc != null){
      if(isInMsg){
        return ([
            <div className="direct-chat-msg">
              <div className="direct-chat-infos clearfix" style={{display:'none',}}>
                <span className="direct-chat-name float-left">Moi</span>
                <span className="direct-chat-timestamp float-right">{window.formateDate(msg.date_creation)}</span>
              </div>
              <img className="direct-chat-img" src={msg.avatar} alt="IMG"/>
              <div className="direct-chat-text">
              <img src={fileSrc} style={{width:'60%'}} />
              </div>
            </div>
        ]);  
      } else{
        return ([
          <div className="direct-chat-msg right" style={{textAlign: (isInMsg?'left':'right')}}>
              <div className="direct-chat-infos clearfix">
                <span className="direct-chat-name float-right">
                    {currPseudoCli.pseudo} [{currPseudoCli.numero}]
                </span>
                <span className="direct-chat-timestamp float-left" style={{fontSize: '10px'}}>{window.formateDate(msg.date_creation)}</span>
              </div>
              <img className="direct-chat-img" src={currPseudoCli.avatar} alt="IMG"/>
              <div className="direct-chat-text">
                <img src={fileSrc} style={{width:'60%'}} />
              </div>  
          </div>  
        ]);  
        }
    } else if(isInMsg){
        return ([
        <div className="direct-chat-msg">
        <div className="direct-chat-infos clearfix" style={{display:'none',}}>
          <span className="direct-chat-name float-left" style={{display:'none'}}>>Moi</span>
          <span className="direct-chat-timestamp float-right">{window.formateDate(msg.date_creation)}</span>
        </div>
        <img className="direct-chat-img" src={msg.avatar} alt="IMG"/>
        <div className="direct-chat-text">
          {msg.message}
        </div>
      </div>]);
    } else if(msg.pseudo == currPseudo && msg.pseudo_src != null){
        return ([
          <div className="direct-chat-msg right">
            <div className="direct-chat-infos clearfix">
              <span className="direct-chat-name float-right">
                  <a href="javascript:" data-toggle="modal" data-target="#match-modal">
                    <img src="resources/img/coeur_brise.png" style={{width:'16px', marginRight: '5px'}} />
                    <img src="resources/img/coeur_ok.png" style={{width:'16px'}} />
                  </a>
        
              </span>
              <span className="direct-chat-timestamp float-left" style={{display:'none',}}>{window.formateDate(msg.date_creation)}</span>
            </div>
            <img className="direct-chat-img" src={currPseudoCli.avatar} alt="IMG"/>
            <div className="direct-chat-text">
              {msg.message}
            </div>
          </div>]);
    } else{
        return <h1>-------------------</h1>
    }
  }

  render(){
   // const DIR_ARRAY = ['STARS', 'DESIGN', 'CARTOON'];
  //  const DIR_COUNT = [152, 50, 110];

    return (
    <div className="container">
    <div class="turnDeviceNotification" style={{display: 'none'}}></div>
        {/* -------------------- MATCH MODAL ----------------- */}
        <div className="modal fade" id="match-modal" tabIndex="-1" role="dialog" aria-labelledby="match-modalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="match-modalLabel">Love / not love</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="match-close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                  <div className="row" style={{width: '105%', height:'150px'}}>
                      <center style={{width: '100%'}} dangerouslySetInnerHTML={{__html: '<img style="height: 114px;border-radius: 50px;" src="'+this.state.client_dest.avatar+'">'}}></center>
                  </div>
                  <div className="row">
                    <div className="col-12" style={{textAlign: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '28px',
            borderBottom: '1px solid #000'}}>
                      {this.state.client_dest.pseudo}
                      <br></br>
                      {this.state.client_dest.numero}
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-6" style={{textAlign: 'center'}}>
                        <a href="javascript:" onClick={() => this.sendMessage('KO_HEART')}>
                          <img src="resources/img/coeur_brise.gif" style={{width:'80px',marginTop:'20px'}} />
                        </a>
                      </div>
                      <div className="col-6" style={{textAlign: 'center'}}>
                        <a href="javascript:" onClick={() => this.sendMessage('OK_HEART')}>
                          <img src="resources/img/love_a.gif" style={{width:'120px'}} />
                        </a>  
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        {/* -------------------- AVATAR MODAL ----------------- */}
        <div className="modal fade" id="avatar-modal" tabIndex="-1" role="dialog" aria-labelledby="avatar-modalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="avatar-modalLabel">Votre photo</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="avatar-close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <input type="file" id='upload_input' name='upload_input' onChange={(event) => this.uploadAvatar(event)} style={{display:'none'}}></input>
                    <a href='javascript:' id='upload_lnk' style={{
                        height: '154px',
                        width: '45%',
                        backgroundColor: 'white',
                        float: 'left',
                        marginLeft: '28%',
                        textAlign: 'center',
                        paddingTop: '38px',
                        borderRadius: '7px'}}>
                        <i class="fa fa-fw fa-camera" style={{fontSize: '65px'}}></i>
                    </a>
                  </div>
                  <div className="col-12" style={{textAlign:'center'}}>
                      <button id='upload_close' style={{width: '120px',marginTop: '21px'}} type="button" className="btn btn-primary">
                        VALIDER
                      </button>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>

        {/* -------------------- SERACH MODAL ----------------- */}
        <div className="modal fade" id="search-modal" tabIndex="-1" role="dialog" aria-labelledby="search-modalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="search-modalLabel"></h5>
                <button type="button" id="search_close" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row" style={{ display:'contents',textAlign:'center'}}>
                     {/*
                      <div className="col-12">
                        <label>Un pseudo :</label>
                        <input type="text" id="search_pseudo" className="form-control" placeholder="Un pseudo" />
                    </div>  
                   
                    <br/> 
                    Ou 
                  */}<div style={{marginLeft:'10px',fontSize:'2.05em'}}>
                  <h1 >Je recherche ...</h1>
                    </div>
                    <br/>

                    <div className="col-12" style={{textAlign:'center'}}>
                        <label style={{fontWeight: 'bold'}}>Un numéro :</label>
                        <input type="text" id="search_numero" className="form-control" style={{borderRadius:'4px',width: '50%', marginLeft: '25%', textAlign: 'center', fontSize: '21px', fontWeight: 'bold', boxShadow: 'inset 0px 1px 8px rgba(29, 11, 16, 0.2)'}} />
                      <br/>
                      <label style={{fontWeight: 'bold'}}>... ou <br/>
                      un genre :</label>
                      <br/>   
                        <select className="form-control" id="search_civilite" style={{borderRadius:'4px',width: '50%', marginLeft: '25%', textAlign: 'center', fontSize: '21px', fontWeight: 'bold', boxShadow: 'inset 0px 1px 8px rgba(29, 11, 16, 0.2)'}} placeholder="un homme, une femme ... autre ?">
                          <option value=""></option>
                          <option>un homme</option>
                          <option>une femme</option>
                        </select>  
                      </div>
                     
                      <div style={{display:'none'}}>                   
                        <label style={{fontWeight: 'bold',marginTop: '16px'}}>À quel fruit voulez vous goûter ?</label>
                        <div id="search-cat-div" className="row" style={{background: '#FFF', marginRight: '0px',marginLeft: '0px', borderRadius: '10px', height: '200px'}}>
                          <div className="col-3" style={{borderRadius: '0px 10px 10px 10px',boxShadow:'0.3rem 0.3rem 0.6rem var(--greyLight-2), -0.2rem -0.2rem 0.5rem var(--white)'}}>
                              <span className="span-cat-img" style={{marginBottom:'5px'}}><img src="resources/img/icons/cerise_photo.png" width="75px" /></span>
                             
                              <span className="span-cat-txt-step1">Trouver sa moitiée</span>
                          </div>
                          <div className="col-3" style={{borderRadius: '0px 10px 10px 10px'}}>
                              <span className="span-cat-img" style={{marginBottom:'5px'}}><img src="resources/img/icons/fraise_photo.png" width="75px" /></span>
                              
                              <span className="span-cat-txt-step1" >Une soirée aux plaisirs sucrés</span>
                          </div>
                          <div className="col-3" style={{borderRadius: '0px 10px 10px 10px'}}>
                              <span className="span-cat-img" style={{marginBottom:'5px'}}><img src="resources/img/icons/pasteque_photo.png" width="75px" /></span>
                              
                              <span className="span-cat-txt-step1" >Des câlins sans pépins</span>
                          </div>
                          <div className="col-3" style={{borderRadius: '0px 10px 10px 10px'}}>
                              <span className="span-cat-img" style={{marginBottom:'5px'}}><img src="resources/img/icons/raisin_photo.png" width="75px" /></span>
                            
                              <span className="span-cat-txt-step1" >Un verre sans se prendre la grappe</span>
                          </div>
                      </div>
                      <input type="hidden" id="search_objectif" name="search_objectif"></input>
                      
                      {/*
                        <select className="form-control" id="search_objectif" style={{width:'100%'}} placeholder="un homme, une femme ... autre ?">
                          <option value=""></option>
                          <option>là pour m’amuser</option>
                          <option>là juste pour boire un verre entre amis</option>
                          <option>là pour trouver l’amour</option>
                          <option>là pour un soir ou pour la vie</option>
                          <option>là comme ça</option>
                        </select>
                      */}
                     
                  </div>
                </div>


                  <div className="row">
                    <div className="col-12" style={{marginTop: '25px', textAlign:'center'}}>
                        <button type="button" onClick={() => this.loadClients(false)} className="btn btn-primary btn-step" ><span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> RECHERCHER</span></button>
                       <br/>
                        <br/>
                      </div>  
                    </div>  
              </div>
            </div>
          </div>
        </div>
      

        {/* -------------------- STEP HOME ----------------- */}
        <div className="row" id="step-home">
            <div className="row top" style={{width: '100%', minHeight:'15%', backgroundImage:'url("resources/img/lajavaFD.png")',backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:'contain',marginLeft:'20px',marginRight:'20px' }}>
            </div>
            <div className="row" style={{marginTop: '15px'}}>  
              <div className="col-12" style={{textAlign: 'center', padding: '10px'}}>
                   <h1 >Bienvenue !</h1>
   
                 <p style={{color: '#FFF'}}>
                 <h4 style={{color: '#FFF'}}>Entrez votre numéro</h4>
                 </p>
                <input placeholder=""type="text" id="token" name="token" maxLength="4" autoComplete="off" className="form-control" style={{borderRadius:'4px',width: '50%', marginLeft: '25%', textAlign: 'center', fontSize: '21px', fontWeight: 'bold', boxShadow: 'inset 0px 1px 8px rgba(29, 11, 16, 0.2)'}}></input>
              
                <br></br>
                <button type="button" onClick={() => this.connectByNumero()} className="btn btn-primary btn-step"><span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> C'EST PARTI !</span></button>
                <br></br>
                <br></br>
                <p style={{color: '#eca0c6'}}>
                <img src="resources/img/boule.png" height='8px' style={{marginBottom:'5px',marginRight:'5px',}}></img>Aucune information personnelle n'est enregistrée<br></br>         <img src="resources/img/boule.png" height='8px' style={{marginBottom:'5px',marginRight:'5px',}}></img>Les conversations et échanges sont privés.<br></br>
                        <img src="resources/img/boule.png" height='8px' style={{marginBottom:'5px',marginRight:'5px',}}></img>L'ensemble des données techniques seront supprimées à la fin de la soirée.</p>
              </div>
            </div>
        </div>

        {/* -------------------- STEP IDENTITY ----------------- */}
        <div className="row" id="step-identite" style={{display: 'none'}}>
            <div className="row" style={{width: '100%', height:'15px',}}>
                <a href="javascript:" className="step-screen" onClick={() => this.handelStep('home')}><i className="fa fa-fw fa-chevron-left" style={{color:'#FFF',marginTop:'7px',marginleft:'7px',fontSize:'18px'}}></i></a>
            </div>
              <div className="col-12">
              <div className="col-7" style={{float: 'left'}}>
                    <p className="messageInfo" style={{marginLeft:'0px',marginRight: '0px',textAlign:'left'}}>Vous êtes ...</p>

                    <select className="form-control" id="civilite" name="civilite" style={{width:'100%',marginLeft:'auto', marginRight:'auto',}}>
                      <option value=""></option>
                      <option>un homme</option>
                      <option>une femme</option>
                    {/* <option>non binaire</option> */}
                    </select>
                 </div>
                 <div className="col-5" style={{float: 'left',marginBottom: '20px'}}>   
                   <p className="messageInfo" style={{marginLeft:'0px',marginRight: '0px',textAlign:'left'}}>Votre âge ...</p>

                    <select className="form-control" id="age" name="age" style={{width:'100%',marginLeft:'auto', marginRight:'auto',}}>
                      <option value=""></option>
                      <option>18-25</option>
                      <option>25-30</option>
                      <option>30-35</option>
                      <option>40-45</option>
                      <option>+45</option>
                    </select>
                </div>
               
                <p className="messageInfo">Quel fruit voulez vous être ce soir ? </p>

                  <div id="cat-div" className="row" >
                      <div className="col-3" style={{borderRight:"1px solid #e75190"}} >
                          <span className="span-cat-img"><img src="resources/img/icons/cerise_photo.png" /></span>
                          <span className="span-cat-txt-step1">Trouver sa moitiée<br/>...</span>
                      </div>
                      <div className="col-3" style={{borderRight:"1px solid #e75190"}} >
                          <span className="span-cat-img"><img src="resources/img/icons/fraise_photo.png" /></span>
                          <span className="span-cat-txt-step1">Une soirée aux plaisirs sucrés</span>
                      </div>
                      <div className="col-3" style={{borderRight:"1px solid #e75190"}} >   
                         <span className="span-cat-img"><img src="resources/img/icons/pasteque_photo.png" /></span>
                         <span className="span-cat-txt-step1">Des câlins sans pépins<br/>...</span>
                      </div>
                      <div className="col-3" >
                          <span className="span-cat-img"><img src="resources/img/icons/raisin_photo.png" /></span>
                          <span className="span-cat-txt-step1">Un verre sans se prendre la grappe</span>
                      </div>
                  </div>
                  <input type="hidden" id="cause" name="cause"></input>
              </div>
              
              <div className="col-12" data-toggle="modal" data-target="#avatar-modal" style={{marginTop: '0px', textAlign: 'center'}} dangerouslySetInnerHTML={{__html: '<img style="height: 114px; width:114px; object-fit: cover; border-radius: 50%;" class="img-avatar-'+this.state.civilite.split(" ")[1]+'" src="'+this.state.avatar+'">'}}>
                    
              </div>
              <div className="col-12" style={{marginTop: '0px',marginLeft:'auto',marginRight:'auto',textAlign:'center'}}>
                <button type="button" id="avatar-btnPop" onClick={() => this.setState({is_change_avatar:false})} data-toggle="modal" data-target="#avatar-modal" className="btn btn-primary btn-step" style={{marginLeft:'auto',marginRight:'auto'}}>
                    <span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> CHOISIR SON AVATAR</span>
                 </button>
                <button type="button" id="avatar-btnNext" onClick={() => this.handelStep('pseudo')} className="btn btn-primary btn-step" style={{display: 'none'}}>
                    <span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> CONTINUER</span>
                 </button>
              </div>  
        </div>

        {/* -------------------- STEP LOGIN ----------------- */}

        <div className="row" id="step-pseudo" style={{display: 'none'}}>
            <div className="row" style={{width: '100%', height:'15px'}}>
                <a href="javascript:" className="step-screen" onClick={() => this.handelStep('identite')}><i className="fa fa-fw fa-chevron-left" style={{color:'#FFF',marginTop:'7px',marginleft:'7px',fontSize:'18px'}}></i></a>
            </div>
            <div className="col-12" style={{textAlign:"center"}}>
      
                    <div dangerouslySetInnerHTML={{__html: '<img style="margin-left:auto; margin-right:auto; height: 200px; width:200px; object-fit: cover; border-radius: 50%; border-style:solid;border-width:5px;border-color:#FFF;" src="'+this.state.avatar+'">'}}></div>
 
              <div className="col-12" style={{textAlign: 'center', padding: '23px'}}>
                <h3>Quel est votre pseudo ? </h3>
                <input type="text" id="pseudo" name="pseudo" maxLength="20" autoComplete="off" className="form-control" style={{width: '50%', marginLeft: '25%', textAlign: 'center', fontSize: '21px', fontWeight: 'bold',textTransform: 'uppercase'}}></input>
                </div>
                <div className="col-12" style={{textAlign: 'center'}}>
                  <button type="button" onClick={() => this.handelStep('recap-cpt')} className="btn btn-primary btn-step"><span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> CONTINUER</span></button>
              </div>
            </div>
        </div>

        {/* -------------------- STEP USER RECAP ----------------- */}
        <div className="row" id="step-recap-cpt" style={{display: 'none'}}>



<div className="row" style={{width: '100%', height:'15px'}}>
                <a href="javascript:" className="step-screen" onClick={() => this.handelStep('pseudo')}><i className="fa fa-fw fa-chevron-left" style={{color:'#FFF',marginTop:'7px',marginleft:'7px',fontSize:'18px'}}></i></a>
            </div>

            <div className="row" style={{width: '105%', height:'150px' }}>
                <center style={{width: '100%'}} dangerouslySetInnerHTML={{__html: '<img style="height: 120px; width:120px; object-fit:cover; border-radius: 50%; border-width:5px;" class="img-avatar-'+this.state.civilite.split(' ')[1]+'" src="'+this.state.avatar+'">'}}></center>
            </div>
            <div className="row" style={{marginTop:'-20px'}}>  
              <div className="col-12" style={{textAlign: 'center', padding: '23px', color:'#FFF', fontWeight: 'bold'}}>
                <h4>Merci <span style={{fontWeight: 'bold'}}>{this.state.pseudo}</span> </h4>
                <h1 style={{fontSize:'2em',}}> si tu vois quelqu’un qui te correspond,
                recherche son numéro et discutez ensemble !... </h1>
  
                
                <button type="button" onClick={() => this.manageUser()} className="btn btn-primary btn-step"><span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> CONTINUER></span></button>
              </div>
            </div>
        </div>

        {/* -------------------- STEP SEARCH USERS ----------------- */}
        <div className="row" id="step-recherche" style={{display: 'none'}}>

 <div className="card-header" style={{height:'110px',width:'100%',textAlign:'center',color:'#FFF'}}>

  
  <a href="javascript:" style={{position: 'absolute', left: '8px', top:'15px', display:'none'}} onClick={() => this.loadClients(true)} className="step-screen-icon">
                    <i class="fa fa-fw fa-chevron-left"></i>

                </a>
  <span className="title-chat-top"style={{textAlign:'center',width:'100%'}}>Les participants</span>
  <a href="javascript:" style={{position: 'absolute', right: '10px', top: '15px'}} onClick={() => this.handelStep('historique')} className="step-screen-icon">
                  <i className="msg-notread fa fa-fw fa-bell" style={{color: '#FFF', display:this.state.is_new_msg?'':'none'}}></i>
               <i class="fa fa-comments-o" aria-hidden="true" ></i> </a>
<div style={{clear:'both'}}>
                <a href="javascript:"  data-toggle="modal" data-target="#search-modal" className="step-screen" style={{marginLeft:'auto',marginRight:'auto'}}>
         
        <button type="button" className="button-search" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto', color:'#6c757d', backgroundColor:'#ececec'}} > <i class="fa fa-fw fa-search"></i> <span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> Rechercher</span></button>

                </a>
              </div>
            </div>
            <div className="row" id="search-div" style={{width:'100%'}}>
              <div className="col-12" style={{paddingTop:'30px',paddingBottom:'90px'}}>
                <ul className="users-list clearfix">
                    {this.state.clients.map((client) =>
                        <li style={{width: '33%'}}>
                          <a className="users-list-name" cl={client.pseudo} href="#">
                        {/* -------------------- Modif avatar BDH ----------------- */}
                            <img src={client.avatar} alt={client.pseudo} className={'img-avatar-'+client.civilite.split(' ')[1]} style={{width:'100px',height:'100px', objectFit:'cover',}} />
                            <span className="users-list-date" style={{fontWeight: 'bold', fontSize:'14px'}}>{client.pseudo}</span>
                            <span className="users-list-date">{client.numero}</span>
                          </a>  
                      </li>
                      )}
                  </ul>
              </div>
            </div>  
        </div>

        {/* -------------------- STEP HISTORY ----------------- */}
        <div className="row" id="step-historique" style={{display: 'none'}}>
           <div className="card-header" style={{height:'110px',width:'100%',textAlign:'center',color:'#FFF'}}>

  
  <a href="javascript:" style={{position: 'absolute', left: '8px', top:'15px',}} onClick={() => this.loadClients(true)} className="step-screen-icon">
                    <i class="fa fa-fw fa-chevron-left"></i>

                </a>
  <span className="title-chat-top"style={{textAlign:'center',width:'100%'}}>Mes discussions</span>
 

              <div style={{clear:'both'}}>
                <a href="javascript:"  data-toggle="modal" data-target="#search-modal" className="step-screen" style={{marginLeft:'auto',marginRight:'auto'}}>
         
        <button type="button" className="button-search" style={{textAlign:'center',marginLeft:'auto',marginRight:'auto', color:'#6c757d', backgroundColor:'#ececec'}} > <span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> <i class="fa fa-fw fa-search"></i> Rechercher</span></button>

                </a>
              </div>
            </div>


            <div className="row" id="histo-div" style={{width: '100%', paddingTop:'10px',paddingBottom:'110px'}}>
              <div className="col-12">
                <table style={{width: '100%'}}>
                    {this.state.historiques.map((obj, idx) =>
                        <tr style={{ }}>
                          <td style={{width: '50px'}}>
                            <i className="msg-notread fa fa-fw fa-bell" style={{
                                  display:this.keyExists(msgsAllState, obj.psd)?'':'none', 
                                  color: '#e75190',
                                  position: 'absolute', 
                                  left: '39px', 
                                  fontSize:'16px', 
                                  top:(idx*77)
                                }}></i>
                            <a className="users-name" cl={obj.cli?.pseudo} href="javascript:"> 
                              <img style={{width: '60px', height: '60px', objectFit: 'cover', borderRadius: '50%', marginLeft:'10px'}} className={'img-avatar-'+obj.cli?.civilite.split(' ')[1]} src={obj.cli?.avatar} alt={obj.cli?.pseudo} />
                            </a>
                          </td>
                          <td style={{padding:'15px',}}>
                            <a className="users-name" cl={obj.cli?.pseudo} href="javascript:">
                              <span>{obj.cli?.pseudo} </span>
                              <span style={{position: 'absolute', right: '15px', fontSize:'10px'}}>{this.diff_date_time(obj.msg.date_creation)}</span>
                              <br/>
                              <span className="users-list-date" style={{ fontSize: '14px'}}>
                                  {obj.msg.message=='OK_HEART'?'Coeur':(obj.msg.message=='KO_HEART'?'Coeur brisé':(obj.msg.message=='UPFILE'?'Image':obj.msg.message.substring(0, 25) + "..."))}
                              </span>
                            </a>
                          </td>    
                        </tr>
                      )}
                  </table>
              </div>
            </div>  
        </div>
        
        {/* -------------------- STEP USERS INFOS ----------------- */}
        <div className="row" id="step-recap" style={{display: 'none'}}>
 <div className="card-header" style={{height:'35px',width:'100%',textAlign:'center',color:'#FFF'}}>

  
  <a href="javascript:" style={{position: 'absolute', left: '8px', top:'15px',}} onClick={() => this.loadClients(true)} className="step-screen-icon"><i class="fa fa-fw fa-chevron-left"></i></a>
  <span className="title-chat-top"style={{textAlign:'center',width:'100%'}}> </span>
  <a href="javascript:" style={{position: 'absolute', right: '10px', top: '15px'}} onClick={() => this.handelStep('historique')} className="step-screen-icon">
                  <i className="msg-notread fa fa-fw fa-bell" style={{color: '#FFF', display:this.state.is_new_msg?'':'none'}}></i>
               <i class="fa fa-comments-o" aria-hidden="true" ></i> </a>


            </div>
<div className="row" style={{width: '100%'}}>  
          <span style={{width:'100%',textAlign:'center',}}>
                    <div style={{marginBottom:'15px'}} dangerouslySetInnerHTML={{__html: '<img style="height: 130px; width: 130px; object-fit: cover; border-radius: 50%; border-width:5px; border-color:#FFF; border-style:solid;" src="'+this.state.client_dest.avatar+'">'}}></div>
                     <h2 style={{color:'#FFF'}}>{this.state.client_dest.pseudo} </h2>
     
                <h3>N° {this.state.client_dest.numero} - {this.state.client_dest.age} ans</h3>
 <span className="messageInfo">Je suis là pour </span><h1 style={{textAlign: 'center',fontWeight: 'light', fontSize: '2em', paddingRight: '23px',paddingLeft: '23px'}}>{this.state.client_dest.objectif}</h1>
               </span>

               <div className="col-12" style={{textAlign: 'center'}}>

               

                <button type="button" onClick={() => this.handelStep('chat')} className="btn btn-primary btn-step"><span style={{textShadow:'2px 2px 2px rgba(0,0,0,0.15)'}}> DISCUTER AVEC {this.state.client_dest.pseudo}</span></button>
                <br/><br/>
              </div>

        </div>
</div>

        {/* -------------------- STEP CHAT ----------------- */}
        <div className="row" id="step-chat" style={{display: 'none'}}>
 <div className="card-header" style={{height:'60px',width:'100%',textAlign:'center',color:'#FFF'}}>

  
  <a href="javascript:" style={{position: 'absolute', left: '8px', top:'15px',}} onClick={() => this.handelStep('historique')} className="step-screen-icon">
                    <i class="fa fa-fw fa-chevron-left"></i>

                </a>
  <span className="title-chat-top"style={{textAlign:'center',width:'100%'}}>
<div style={{position: 'absolute', left:'40%', top:'10px'}}>
                          <div dangerouslySetInnerHTML={{__html: '<img style="height: 48px; width:48px; object-fit: cover; border-radius: 50%; border-width:3px; border-color:#FFF; border-style:solid;" src="'+this.state.client_dest.avatar+'">'}}></div>   
                          <span style={{position: 'absolute',top: '-4px',left: '48px',fontWeight: 'bold',color: 'white',fontSize: '17px',marginLeft:'14px'}}>{this.state.client_dest.pseudo}</span>
                          <span style={{position: 'absolute',top: '12px',left: '48px',color: 'white',fontSize: '13px',marginLeft:'14px',fontWeight:'nomal'}}>{this.state.client_dest.numero}</span>
                      </div>
  </span>
  <a href="javascript:" style={{position: 'absolute', right: '10px', top: '15px'}} onClick={() => this.handelStep('historique')} className="step-screen-icon">
                  <i className="msg-notread fa fa-fw fa-bell" style={{color: '#FFF', display:this.state.is_new_msg?'':'none'}}></i>
               <i class="fa fa-comments-o" aria-hidden="true" ></i> </a>


            </div>


            <div className="card direct-chat direct-chat-warning" id="chat-div" 
                  style={{width:'100%', 
                      marginLeft: '0px', 
                      marginRight: '-15px',
                      marginBottom: '58px'}}>
              
                  <div className="card-body" style={{paddingTop: '0px'}}>
                    <div className="direct-chat-messages" id="msgs_div">
                      {this.state.messages.map((msg) =>   
                        <this.manageMessage msg={msg} psd={this.state.pseudo} cli={this.state.client_dest} />
                      )}
                    </div>
                  </div>
                  <div className="card-footer" style={{position: 'fixed',bottom: '0px',left: '0px',width: '100%'}}>
                      <a id="cam_lnk" href="javascript:" style={{position: 'absolute', left: '8px', bottom:'7px',zIndex:'999999'}} className="step-screen-icon-bottom">
                        <i class="fa fa-fw fa-camera"></i>
                      </a>
                      <input type="file" id="cam_input" onChange={(event) => this.uploadFile(event)} style={{display:'none'}} />
                      <div className="input-group" style={{zIndex:'0',}}>
                        <input type="text" id="message" name="message" autoComplete="off" placeholder="Mon message ..." className="form-control" style={{marginRight: '50px', marginLeft:'50px'}}/>
                      </div>
                      <a onClick={() => this.sendMessage(null)} href="javascript:" style={{position: 'absolute', right: '10px', bottom: '7px',zIndex:'999999'}} className="step-screen-icon-bottom">
                        <i class="fa fa-fw fa-send" style={{paddingLeft: '6px',paddingTop: '6px', marginRight:'4px',}}></i>
                      </a>
                  </div>
                </div>
        </div>
    </div>

     ) }
}

export default Chat;
