import React from 'react';
import './App.css';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Chat from "./Chat";
import Synthese from "./Synthese";

import {
  getFirestore,
  collection,
  getDocs,
  query,
  deleteDoc,
  doc
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  listAll,
  deleteObject
} from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAXffTkHRd5pZGrakFzSYFfT61ht_fR4-I",
  authDomain: "lajava-d31d2.firebaseapp.com",
  databaseURL: "https://lajava-d31d2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lajava-d31d2",
  storageBucket: "lajava-d31d2.appspot.com",
  messagingSenderId: "757774678713",
  appId: "1:757774678713:web:b2de0fdf7acf2d26397968"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

class App extends React.Component {
  componentDidMount(){
    
  /*  const messaging = getMessaging();
    getToken(messaging, { vapidKey: "BOrgAwXARUJyfhxycoHSpGWanrmE8llXaUdSqhZaxFaRTHer5Gh6_8MGWyt4Gxn1boLNy0wskLshYRXCSntxRz4"}).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        alert('Token ==>'+currentToken);
        mesg_token = currentToken;
      } else {
        // Show permission request UI
        alert('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => {
      alert('An error occurred while retrieving token. '+err.message);
      // ...
    });
    onMessage(messaging, (payload) => {
      alert('Message received. ', payload);
      // ...
    }); 
    */
    
  }

  /**
   * Purger les données de plus d'un certain temps
   */
   async clearChatHistory(){
    let q = query(collection(db, 'utilisateurs'));
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach((cli) => {
        deleteDoc(doc(db, "utilisateurs", cli.data().id));
    });
  
    // Messages
    q = query(collection(db, 'messages'));
    querySnapshot = await getDocs(q);
    querySnapshot.forEach((cli) => {
       deleteDoc(doc(db, "messages", cli.data().id));
    });

    // Fichiers
    // Create a reference to the file to delete
    var storage = getStorage(app);

    const listRef = ref(storage, 'images');
    listAll(listRef).then((res) => {
      /*  res.prefixes.forEach((folderRef) => {
        
        });*/
        res.items.forEach((itemRef) => {
          const imgRef = ref(storage, itemRef); 
          // Delete the file
          deleteObject(imgRef).then(() => {

          });   
        });
    });
  }

  render(){
    if(window.location.href.indexOf("synthese") != -1){
        return <Synthese firebase={app}/>
    } else if(window.location.href.indexOf("chatclear") != -1){
      this.clearChatHistory();
      return "OK"
    } else{
      return <Chat firebase={app} />
    }
  }
}

export default App;
